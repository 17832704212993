
import './App.css';
function App() {
  
  return (
    <main>
		<div className="flight-hacks-wine">
			<div className="div">
				<nav className="navbar navbar-expand-lg  navbar-dark" style={{backgroundColor:"#1e2128",minHeight:"61px",zIndex:"1"}}>
					<div className="container-fluid" style={{marginLeft:"134px",marginRight:"134px"}}>
						<a className="navbar-brand" href="#">
							<div className="group-4">
								
									<img className="union" alt = "union-1" src="/img/flight-hacks-light.svg" />
									
							</div>
						</a>
						<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>
						<div className="collapse navbar-collapse" id="navbarText" style={{float: "right"}}>
						  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
							<li className="nav-item">
							  <a className="nav-link active" aria-current="page" href="#"><div className="text"><div className="link-home">Home</div></div></a>
							</li>
							<li className="nav-item">
							  <a className="nav-link" href="#">	<div className="text"><div className="link-home">Posts</div></div></a>
							</li>
							<li className="nav-item">
							  <a className="nav-link" href="#"><div className="text"><div className="link-home">Tools</div></div></a>
							</li>
							<li className="nav-item">
							  <a className="nav-link" href="#"><div className="link-home-wrapper"><div className="link-home-2">Contact</div></div></a>
							</li>
							<li className="nav-item">
							  <a className="nav-link" href="#"><img className="frame-8"  alt = "frame-8" src="/img/frame-8.svg" /></a>
							</li>
						  </ul>
						</div>
					</div>
				</nav>
				<div className="container">
					<div className = "row">
						<img className="radar" alt = "radar" src="/img/radar.png" />
						<img className="union-2" alt = "union-2" src="/img/union-3.svg" />
						<img className="union-3" alt = "union-3" src="/img/union-2.svg" />
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-12 text-center" style = {{marginTop:"60px"}}>
							<div className="frame-16">
								<div className="frame-4">
									<div className="frame-17">
										<div className= "container" >
											<div className= "row" >
												<div className= "col-lg-5 col-sm-12 head1">
													<div className="text-wrapper-13">Qantas Wine</div>
												</div>
												<div className= "col-lg-2 col-sm-12 text-center" style={{textAlign: "center", justifyContent: "center", alignItems: "center", display: "flex", padding:"0"}}>
													<div className="overlap-group-wrapper">
														<div className="overlap-4">
															<div className="overlap-5">
																<div className="overlap-group-3">
																	<img className="group-5"  alt = "group-5"  src="img/group-9.png" /> <img className="group-6" alt = "group-6" src="img/group-10.png" />
																</div>
																<div className="rectangle-2"></div>
															</div>
															<div className="rectangle-3"></div>
															<div className="rectangle-4"></div>
														</div>
													</div>
												</div>
												<div className= "col-lg-5 col-sm-12 head2">
													<div className="text-wrapper-13">Bonus tracker</div>
												</div>
											</div>
										</div>
										
									</div>
									<p className="uncork-the-best">
									<span className="text-wrapper-14">Uncork the Best Deals:</span>
									<span className="text-wrapper-15"> Discover Exclusive Wine Savings Daily!</span>
									</p>
								</div>
								<div className="frame-4">
									<p className="text-wrapper-16">
										We’ve created the Qantas Wine tracker so you can quickly identify the best bonus points deals on offer
										via the Qantas Wine website. Our valuations are updated as soon as you load this page, enjoy!
									</p>
									<div className="frame-18">
										<img className="attachment" alt = "attachment" src="img/attachment-1.png" />
										<div className="text-wrapper-17">Immanuel - Flight Hacks</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container" style={{position:"relative",maxWidth:"1270px"}}>
					<div className="row">
						<div className="col-12">
							<div className="overlap-wrapper">
								<div className="overlap-3">
									<img className="ellipse-3" alt = "ellipse-3" src="img/ellipse-1.svg" />
									<img className="ellipse-4" alt = "ellipse-4" src="img/ellipse-2.svg" />
									<img className="rectangle" alt = "rectangle" src="img/rectangle-7.png" />
								</div>
							</div>
							<div className="frame-9">
								<div className="container">
									<div className="row">
										<div className="col-xl-4 col-lg-12" style={{margin: "auto",textAlign:"center"}}>
											<div className="frame-10">
												<img className="img-2" alt = "img-2" src="img/trophy.svg" />
												<p className="text-wrapper-9">The top 10 deals are:</p>
											</div>
										</div>
										<div className="col-xl-6 col-lg-12" style={{margin: "auto",textAlign:"center"}}>
											<div className="frame-11">
												<div className="text-wrapper-10">Updates hourly. Last update:</div>
												<div className="frame-12">
													<div className="frame-13">
														<img className="img-3" alt = "img-3" src="img/clock.svg" />
														<div className="text-wrapper-11" id = "lblLastUpdateTime"></div>
													</div>
													<div className="frame-14">
														<img className="img-3" alt = "img-3" src="img/calendarblank.svg"  alt-text = "calendar"/>
														<div className="text-wrapper-11" id = "lblLastUpdateDate"></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-2 col-lg-12" style={{textAlign:"center",justifyContent: "center",alignItems: "center",display:"flex"}}>
											<select className = "frame-15" id = "select-option-top-deals" >
												<option value = "0">Base rate: 0% OFF</option>
												<option value = "1">Points Club - 3 Points Per $1 Spend, 0% discount.</option>
												<option value = "2">Points Club Plus - 3 Points Per $1 Spend, 0% discount.</option>
											</select>
										</div>
									</div>
								</div>
							</div>
							<div className ="container" >
								<div className="row">
									<div className ="col-12" style={{textAlign:"center",justifyContent: "center",alignItems: "center",display:"flex",width:"100%"}}>
										<div className="frame-19" id = "table-top-deals">
											
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
				</div>
				<div className= "container">
					<div className= "row" style = {{    padding: "90px"}}>
						<div className="col-xl-10 col-lg-12">
							<div className="overlap-6">
							  <div className="frame-22">
								<div className="frame-23">
								  <img className="img-2" alt = "img-2" src="img/medal.svg" />
								  <p className="text-wrapper-24">Historical QF Wine Deal Tracker.</p>
								</div>
								
							  </div>
							  <p className="here-s-where-we">
								Here&#39;s where we store all the top deals our tool finds<br />so you can reference previous Qantas Bonus
								wine deals.
							  </p>
							</div>
						</div>
						<div className="col-xl-2 col-lg-12 my-0" style={{marginTop: "0 !important",textAlign: "center", justifyContent: "center", alignItems: "center", display: "flex",verticalAlign: "text-top"}}>
							<select className = "frame-15"id = "select-option-history-deals" >
								<option value = "0">Base rate: 0% OFF</option>
								<option value = "1">Points Club - 3 Points Per $1 Spend, 0% discount.</option>
								<option value = "2">Points Club Plus - 3 Points Per $1 Spend, 0% discount.</option>
							</select>
						</div>
					</div>
					
				</div>
				<div className ="container" style={{position: "relative", maxWidth: "1270px"}}>
					<div className="row">
						<div className ="col-12" style={{textAlign:"center",justifyContent: "center",alignItems: "center",display:"flex",width:"100%",padding:"0"}}>
							<div className="frame-24" id = "table-history-deals">
							  
							</div>
							
						</div>
					</div>
				</div>
				<div className="container" style={{position:"relative",marginTop:"90px"}}>
					<div className = "row">
						<div className="col-12 mb-3 text-center">
							<div className="frame-44">
								<p className="text-wrapper-4">Top 3 Credit Card Bonus Offers</p>
								<p className="enjoy-our-free">
									Enjoy our free Qantas wine deal tracker?<br />You can support our work via these promo offers:
								</p>
							</div>
						</div>
					</div>
					<div className="row frame-3">
						<div className="overlap-group">
							<div className="ellipse"></div>
							<div className="ellipse-2"></div>
						</div>
					</div>
					<div className = "row" >
						<div className ="col-12 mt-4" style={{textAlign:"center",justifyContent: "center",alignItems: "center",display:"flex",width:"100%",padding:"0"}}>
							<div className="frame-5">
								<div className="container">
									<div className="row align-items-center">
										<div className="col-sm-12 col-lg-4 text-center mb-4">
											<div className="frame-44">
												<img className="image"  alt = "image" src="/img/image-4.png" />
												<p className="american-express">
													<span className="span">American Express<br /></span> <span className="text-wrapper-5">Velocity Platinum</span>
												</p>
												<button className= "component btn btn-outline-primary"><div className="text-wrapper-6">APPLY NOW</div></button>
												
											</div>
										</div>
										<div className="col-sm-12 col-lg-4 text-center mb-4">
											<div className="frame-44">
												<img className="img" alt = "image" src="/img/image-2.png" />
												<p className="american-express">
													<span className="span">American Express<br /></span> <span className="text-wrapper-5">Qantas Ultimate</span>
												</p>
												<button className= "component btn btn-outline-primary"><div className="text-wrapper-6">APPLY NOW</div></button>
											</div>
										</div>
										<div className="col-sm-12 col-lg-4 text-center mb-4">
											<div className="frame-44">
												<img className="image" alt = "image" src="/img/image-3.png" />
												<p className="american-express">
													<span className="span">American Express<br /></span>
													<span className="text-wrapper-5">Business Rewards Card</span>
												</p>
												<button className= "component btn btn-outline-primary"><div className="text-wrapper-6">APPLY NOW</div></button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					
				</div>
				<div className="container" style = {{width:"100%",margin:"0",minWidth:"100%"}}>
					<div className="row" >
						<div className="col-12" style={{paddingLeft:"80px",paddingRight:"80px",paddingTop:"80px",paddingBottom:"80px"}}>
							<img className="line" alt = "line" src="img/line-1.svg" />
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="group-2">
								<div className= "container" style = {{width:"100%",margin:"0",minWidth:"100%"}}>
									<div className="row">
										<div className="col-sm-12 col-lg-4 mb-4" style={{textAlign:"center",justifyContent: "center",alignItems: "center",display:"flex"}}>
											<div className="text-wrapper">Privacy policy</div>
											<div className="text-wrapper">Disclaimer</div>
										</div>
										<div className="col-sm-12 col-lg-4 mb-4" style={{textAlign:"center",justifyContent: "center",alignItems: "center",display:"flex"}}>
											
											<div className="overlap-group-2" >
												<img className="union" alt = "union" src="/img/flight-hacks-dark.svg" />
												
											</div>
											
										</div>
										<div className="col-sm-12 col-lg-4 mb-4" style={{textAlign:"center",justifyContent: "center",alignItems: "center",display:"flex"}}>
											<img className="ic-baseline" alt = "baseline" src="/img/ic-baseline-copyright.svg" />
											<p className="p">2024 Flight Hacks. All rights reserved.</p>
										</div>
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
  );
}

export default App;
